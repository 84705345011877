import {Component, inject} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {BorgoService} from '../../services/borgo.service'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatButton} from '@angular/material/button'
import {Router} from '@angular/router'

@Component({
  selector: 'spb-menu',
  templateUrl: './menu.component.html',
  standalone: true,
  imports: [MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem]
})
export class MenuComponent {
  private configService = inject(ConfigService)
  private borgoService = inject(BorgoService)

  private router = inject(Router)

  public logout(): void {
    this.configService.logout()
    this.borgoService.reset()
    this.router.navigate(['/']).then()
  }
}
