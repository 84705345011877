import {Routes} from '@angular/router'
import {ADMIN_HOME_PATH} from './application/types'

export default [
  {
    path: ADMIN_HOME_PATH,
    // Says that anything start with admin goes to admin?
    loadChildren: () => import('./admin/admin-routes')
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ADMIN_HOME_PATH
  },
  {
    path: '**',
    redirectTo: ADMIN_HOME_PATH
  }
] satisfies Routes
