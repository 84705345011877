{
  "name": "borgo-loan-fe",
  "version": "1.1.1-a8d9068d.0",
  "scripts": {
    "watch": "ng test",
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  src/**/*.scss --fix",
    "test": "ng test --watch=false --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/material": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.1",
    "@angular-eslint/builder": "^17.0.1",
    "@angular-eslint/eslint-plugin": "^17.0.1",
    "@angular-eslint/eslint-plugin-template": "^17.0.1",
    "@angular-eslint/schematics": "^17.5.1",
    "@angular-eslint/template-parser": "^17.0.1",
    "@angular/cli": "~18.0.1",
    "@angular/compiler-cli": "^18.0.0",
    "@angular/material-luxon-adapter": "^18.0.0",
    "@sparbanken-syd/borgo-helpers": "^1.1.0",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "~4.3.0",
    "@types/luxon": "^3.4.2",
    "@types/mocha": "^10.0.6",
    "@types/sparbanken-syd-auth-backend": "^2.0.0",
    "@types/sparbanken-syd-borgo": "^3.0.0",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "luxon": "^3.4.4",
    "postcss": "^8.4.31",
    "stylelint": "^15.11.0",
    "stylelint-config-sass-guidelines": "^10.0.0",
    "stylelint-config-standard-scss": "^11.0.0",
    "typescript": "^5.4.5"
  }
}
