import {Component, inject, Type} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {MenuComponent} from '../menu/menu.component'
import {AsyncPipe, NgComponentOutlet, NgIf} from '@angular/common'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'


@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [ThemeModule, NgIf, NgComponentOutlet, AsyncPipe]
})
export class HeaderComponent {

  /**
   * This is just me trying to build a generic
   * header, this is one way of doing it, possibly...
   */
  public hamburgerMenu: Type<any> = MenuComponent

  public configService = inject(ConfigService)


}
